export const structuredData = ({ contactDetails = null, socialLinks = null }) => {
  const str = contactDetails?.address?.value
  const address = str?.split('\n')

  return {
    '@context': 'https://schema.org',
    '@type': 'Corporation',
    name: contactDetails?.seoData?.companyName,
    url: contactDetails?.seoData?.companyUrl,
    logo: contactDetails?.seoData?.companyLogo,
    address: {
      '@type': 'PostalAddress',
      streetAddress: address?.[0],
      addressLocality: address?.[2] || null,
      postalCode: address?.[1] || null,
      addressCountry: address?.[3] || null,
    },
    contactPoint: {
      '@type': 'ContactPoint',
      telephone: contactDetails?.phonenumber?.value || '',
      contactType: 'customer service',
    },
    sameAs: socialLinks?.map((item: any) => item.link?.cachedUrl) || [],
  }
}
