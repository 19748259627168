import React from 'react'

import { HomeHeader } from '@/components/UI'
import Components from '@/components/Components'

import { HEADER_ANIMATION_DELAY } from '@/constants/animations'
import VideoHeader from '@/components/UI/VideoHeader'
import { BlokZone } from '@/components/UI/BlokZone'

interface Props {
  content: any
}

const Presentational: React.FC<React.PropsWithChildren<Props>> = ({ content }) => {
  const { body, header } = content

  const headerTypeNotUnknown = header?.__typename !== 'Unknown' ? header : null

  return (
    <>
      {headerTypeNotUnknown && headerTypeNotUnknown.__typename === 'HeaderVideo' ? (
        <VideoHeader content={header} />
      ) : (
        <HomeHeader content={header} delay={HEADER_ANIMATION_DELAY} />
      )}

      <BlokZone bloks={body} componentLookup={Components} />
    </>
  )
}

export default Presentational
