import React from 'react'

import { useQuery } from '@apollo/client'
import Presentational from '@/screens/Home/Presentational'
import Layout from '@/components/layout'
import { GetHomePageDocument, GetHomePageQuery } from '@/queries/queries/getHomePage.generated'
import { Screen } from '@/types'
import { structuredData } from './structured-data'

type Props = Screen

const Overview: React.FC<React.PropsWithChildren<Props>> = ({ slug }) => {
  const { loading, data } = useQuery<GetHomePageQuery>(GetHomePageDocument, {
    variables: {
      id: slug,
    },
  })

  return (
    <Layout.Default
      contactData={data?.modalContact?.content}
      departmentsContactData={data?.modalDepartmentsContact}
      departmentsNewsletterData={data?.modalDepartmentsNewsletter}
      donationData={data?.modalDonation}
      feedbackData={data?.modalFeedback?.content}
      footerData={data?.footer}
      loading={loading}
      siteData={data?.site}
      newsletterData={data?.modalNewsletter?.content}
      seoData={data?.homePage?.content?.seo}
      structuredData={structuredData({
        contactDetails: data?.modalContact?.content?.contactDetails,
        socialLinks: data?.footer?.content.socialLinks,
      })}
    >
      <Presentational content={data?.homePage?.content} />
    </Layout.Default>
  )
}

export default Overview
