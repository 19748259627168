import { GetStaticPaths, GetStaticProps } from 'next'
import Overview from '@/screens/Home'
import { GetHomePageDocument } from '@/queries/queries/getHomePage.generated'
import { defaultGetStaticPathsFallback, defaultGetStaticProps, getStaticPathForPages } from '@/utils/pageUtils'

export const getStaticPaths: GetStaticPaths = () => {
  return {
    paths: getStaticPathForPages(['']).paths,
    fallback: defaultGetStaticPathsFallback,
  }
}

export const getStaticProps: GetStaticProps = async (context) =>
  defaultGetStaticProps('home', context, { query: GetHomePageDocument }, (data) => data.homePage == null)

export default Overview
